import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Html = styled.article`
  p + p {
    margin-top: 1.5em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1em 0;
  }

  ul {
    margin-left: 1.2em;
    list-style-type: square;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const FormattedHtml = ({ children, ...rest }) => (
  <Html {...rest}>{children}</Html>
);

FormattedHtml.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FormattedHtml;

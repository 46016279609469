import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import FormattedHtml from '../components/formattedHtml/formattedHtml';
import Container from '../components/container/container';
import VerticalText from '../components/verticalText/verticalText';
import Banner from '../components/banner/banner';
import Heading from '../components/heading/heading';
import Footer from '../components/footer/footer';
import { mobileFirstMedia as media } from '../config/theme';

const Grid = styled.div`
  display: grid;
  grid-gap: 40px;
  position: relative;

  @media (${media.xs}) {
    grid-template-columns:
      minmax(25px, 1fr) minmax(50px, 350px) minmax(50px, 350px)
      minmax(25px, 1fr);
  }

  @media (${media.m}) {
    grid-template-columns: 20% 20% 1fr 1fr;
  }
`;

const Subgrid = styled.div`
  display: grid;
  grid-gap: 40px;

  @media (${media.xs}) {
    grid-column: 2 / 4;
  }

  @media (${media.m}) {
    grid-column: 1 / 5;
  }
`;

const HeadingArea = styled.div`
  grid-column: 1 / 5;
`;

const ContentArea = styled.div`
  grid-column: 1 / 5;

  ol {
    list-style-type: decimal;
    margin-left: 1.5em;
  }

  li {
    margin: 0.7em 0;
    font-weight: 300;
    line-height: 1.3em;
  }

  h6 {
    margin: 1.2em 0;
  }

  p + p {
    margin-top: 1em;
  }
`;

const ContactPage = ({
  data: {
    prismicStronaRodo: {
      data: { baner, tytul_sekcji, naglowek_sekcji, tresc_sekcji, body_meta },
    },
  },
}) => {
  const seoData = body_meta && body_meta.find(el => el.slice_type === 'seo');

  return (
    <>
      <SEO
        title={seoData.primary.tytul}
        description={seoData.primary.opis}
        meta={[{ name: 'robots', content: 'noindex, follow' }]}
        url="/rodo/"
      />

      <Banner
        title="Polityka Prywatności"
        image={{ image: baner.localFile.childImageSharp.fluid }}
      />

      <Container
        css={`
          @media (${media.m}) {
            padding-left: 130px;
            padding-right: 130px;
          }

          @media (min-width: 1690px) {
            padding-left: 25px;
          }
        `}
      >
        <Grid>
          <VerticalText text="Polityka Prywatności" />

          <Subgrid>
            <HeadingArea data-aos="fade-up" data-aos-delay="200">
              <Heading
                title={tytul_sekcji.text}
                heading={naglowek_sekcji.html}
              />
            </HeadingArea>

            <ContentArea data-aos="fade-up" data-aos-delay="300">
              <FormattedHtml
                dangerouslySetInnerHTML={{ __html: tresc_sekcji.html }}
              />
            </ContentArea>
          </Subgrid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    prismicStronaRodo {
      data {
        baner {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2450) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tytul_sekcji {
          text
        }
        naglowek_sekcji {
          html
        }
        tresc_sekcji {
          html
        }
        body_meta {
          slice_type
          primary {
            tytul
            opis
          }
        }
      }
    }
  }
`;

export default ContactPage;
